<template>
  <div class="class-list">
    <GlobalInfoBar
      title="渠道账单"
      content="统计门店商或回收商入账/出账到渠道的交易账单"
    />
    <!-- <Info/> -->
    <GlobalChunk icon="search" title="筛选查询">
      <div slot="filter">
        <From :accountType="accountType"/>
      </div>
      <Table :accountType="accountType"/>
    </GlobalChunk>
  </div>
</template>

<script>
import From from "./Form";
import Table from "./Table";
export default {
  name: "class-list",
  components: {
    From,
    Table
  },
  data() {
    return {
      clickShow: '01',
      accountType: "matchChannel",
    };
  },
  created() {
    console.log(this.$route.path);
    this.$store.commit('tagsView/ROUTER_PATH', this.$route.path )
  },
  methods: {
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.class-list{
  .button_top{
    cursor: pointer;
    margin-bottom: 20px;;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .faultBtn{
    width:550px;
    height: 32px;
    background: #F9FBFD;
    border: 1px solid #C1D1FF;
    box-shadow: 0px 4px 8px 0px rgba(196, 197, 198, 0.2);
    border-radius: 21px;
    display: flex;
    .active{
      text-align: center;
      color: white;
      width: 50%;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 30px;
      height: 30px;
      background: #0981FF;
      border-radius: 20px;
    }
    .none{
      text-align: center;
      color: #333;
      width: 50%;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 30px;
      height: 30px;
      border-radius: 20px;
    }
  }
}
</style>