<template>
  <div class="GlobalFormDemo">
    <GlobalForm :init-data="initData" :needBtnLoading="true" :form-item-list="formItemList" :inline="true" :labelWidth="90" round
      @handleConfirm="handleConfirm" confirmBtnName="查询">
      <el-form-item class="el_from" label="回收商名称" slot="operationSelect" style="margin:0">
        <el-select v-model="merchantId" placeholder="请选择回收商" filterable clearable @change="handleChange">
          <el-option v-for="item in shanpsSelectList" :key="item.merchantId" :label="item.merchantName"
            :value="item.merchantId"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="el_from" label="渠道名称"  slot="operationSelect">
              <el-select ref="select1" v-model="matchChannelId" placeholder="请输入查询或选择渠道" filterable clearable
                @change="matchChannelhandleChange">
                <el-option v-for="item in storeoption" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
      <el-form-item class="el_from" label="门店商名称" slot="operationSelect" style="margin:0">
        <el-select v-model="companyId" placeholder="请选择门店商" filterable clearable @change="handleChangeInquiry">
          <el-option v-for="item in InquirySelectList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="el_from" label="交易门店" slot="operationSelect" style="margin:0">
        <el-select v-model="storeId" placeholder="请先选择交易门店" :disabled="isHasCompany" filterable clearable
          @change="handleChangeStore">
          <el-option v-for="item in storeList" :key="item.key" :label="item.value" :value="item.key"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="el_from" label="原始订单号" slot="orderNo" style="margin:0">
        <el-input v-model="orderNo" clearable placeholder="请输入原始订单号查询"></el-input>
      </el-form-item>
      <el-form-item class="el_from" label="账单类型" slot="orderNo">
        <el-select v-model="opt" placeholder="请选择账单类型查询" filterable clearable @change="handleChangeOpt">
          <el-option v-for="item in optList" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="el_from" label="账单子类型" slot="serTypeList" style="margin:0">
        <el-select v-model="serType" placeholder="请选择账单子类型" filterable clearable @change="handleChangeSerType">
          <el-option v-for="item in serTypeList" :key="item.key" :label="item.value" :value="item.key"></el-option>
        </el-select>
      </el-form-item>
      <div slot="AddSlot">
        <el-button size="small" type="danger" @click="handBtn()">下账</el-button>
        <el-button :loading="isExport" size="small" type="success" icon="el-icon-download"
          @click="clickExcel">导出账单</el-button>
      </div>
    </GlobalForm>
  </div>
</template>

<script>
import { formValidateMobile } from "@/utils/form-validate";
import moment from "moment";
import _api from "@/utils/request";
export default {
  name: "Form",
  props: {
    accountType: {
      require: true,
      type: String
    },
  },
  data() {
    return {
      storeoption: [],
      isExport: false,
      startTime: "",
      endTime: "",
      day60: 30 * 24 * 3600 * 1000,
      initData: {
        entryTime: [
          moment()
            .day(moment().day())
            .format("YYYY-MM-DD 00:00:00"),
          moment().format("YYYY-MM-DD 23:59:59"),
        ],
      },
      merchantId: "",
      companyId: "",
      matchChannelId:"",
      storeId: "",
      orderNo: "",
      opt: "",
      shanpsSelectList: [],
      InquirySelectList: [],
      storeList: [],
      serTypeList: [],
      serType: "",
      isHasCompany: true,
      formItemList: [
        { slotName: 'operationSelect' },
        {
          key: "uniqNo",
          type: "input",
          labelName: "账单流水号",
          placeholder: "请输入账单流水号查询",
        },
        { slotName: 'orderNo' },
        { slotName: 'opt' },
        { slotName: 'serTypeList' },
        {
          key: "entryTime",
          type: "pickerOptions",
          labelName: "时间",
          clearable: true,
          lableWidth:'80',
          placeholder: "请选择时间",
          valueFormat: "timestamp",
          pickerOptions: {
            onPick: ({ maxDate, minDate }) => {
              if (minDate && this.pickerMinDate) {
                this.pickerMinDate = null;
              } else if (minDate) {
                this.pickerMinDate = minDate.getTime();
              }
            },
            disabledDate: (time) => {
              if (this.pickerMinDate) {
                return (
                  time.getTime() > this.pickerMinDate + this.day60 ||
                  time.getTime() < this.pickerMinDate - this.day60
                );
              }
              return false;
            },
          },
        },
      ],
      optList: [
        {
          value: '01',
          label: '入账',
        },
        {
          value: '02',
          label: '出账',
        },
        {
          value: '03',
          label: '挂账',
        },
      ]
    };
  },
  created() {
    this.getMatchChannels()
    const params = {
      pageNum: 0,
      pageSize: 999,
    }
    this.orderNo = this.$route.query.orderNo || ""
    _api.listSimpleMerchantView(params).then((res) => {
      if (res.code === 1) {
        this.shanpsSelectList = res.data
      }
    })
    _api.getrchantSelectList().then((res) => {
      if (res.code === 1) {
        this.InquirySelectList = res.data
      }
    })
    _api.getSerTypeList({ accountType: 'matchChannel', opt: this.opt }).then((res) => {
      if (res.code === 1) {
        this.serTypeList = res.data
      }
    })
    this.handleConfirm(this.initData);
  },
  watch: {
    companyId(newVal) {
      console.log(newVal);
      if (newVal != '') {
        this.isHasCompany = false
      } else {
        this.storeId = ''
        this.isHasCompany = true
      }
    },
    accountType(val) {
      if (val == 'merchant') {
        this.orderNo = ""
        this.handleConfirm();
      }
      this.serType = ""
      this.opt = ""
      _api.getSerTypeList({ accountType: val, opt: this.opt }).then((res) => {
        if (res.code === 1) {
          this.serTypeList = res.data
        }
      })
    }
  },
  methods: {
    // 获取渠道
    getMatchChannels() {
      _api.getMatchChannelSelect().then(res => {
        if (res.code === 1) {
          this.storeoption = res.data;
        }
      });
    },
    // 上账/下账 点击
    handBtn(type) {
      this.$router.push({ path: "/payment/PlatformAccount", query: { type } })
    },
    handleConfirm(data,cd) {
      console.log("提交了form", data);
      if (!Array.isArray(data.entryTime)) {
        (this.startTime = null), (this.endTime = null);
      } else {
        (this.startTime = moment(data.entryTime[0]).format("x")),
          (this.endTime = moment(data.entryTime[1]).format("x"));
      }
      let SeachParams = {};
      if (this.orderNo) {
        SeachParams = {
          orderNo: this.orderNo
        };
      }
      if (data) {
        SeachParams = {
          merchantId: this.merchantId || "",
          companyId: this.companyId || "",
          matchChannelId:this.matchChannelId||"",
          storeId: this.storeId || "",
          uniqNo: data.uniqNo || "",
          orderNo: this.orderNo || data.orderNo || "",
          opt: this.opt || "",
          serType: this.serType || "",
          startTime: this.startTime,
          endTime: this.endTime,
          pageNum: 1,
          pageSize: 10,
        };
      }
      SeachParams.disuseLoding= () => {
          if(typeof cd ==='function'){
             cd();
          }
        },
      console.log(SeachParams);
      this.$store.commit("tagsView/SEACH_PARAMS", SeachParams);
    },
    handleChange(val) {
      this.merchantId = val
      console.log(this.merchantId)
    },
    handleChangeSerType(val) {
      this.serType = val
    },
    handleChangeOpt(val) {
      this.opt = val
      this.serType = ""
      _api.getSerTypeList({ accountType: this.accountType, opt: this.opt }).then((res) => {
        if (res.code === 1) {
          this.serTypeList = res.data
        }
      })
    },
    handleChangeInquiry(val) {
      console.log(val)
      this.companyId = val
      if (val != '') {
        this.storeId = ''
        this.getStoreSelectList()
      }
    },
    handleChangeStore(val) {
      console.log(val)
      this.storeId = val
    },
    matchChannelhandleChange(val){
      console.log(val)
      this.matchChannelId = val
    },
    // 获取门店列表
    getStoreSelectList() {
      _api.getStoreSelectList({ companyId: this.companyId }).then(res => {
        console.log(res);
        if (res.code === 1) {
          this.storeList = res.data
        }
      })
    },
    // 导出账单
    clickExcel() {
      let SeachParams = this.$store.state.tagsView.SeachParams
      console.log(SeachParams);
      if (!SeachParams.startTime) {
        this.$message.error('请先选择账单时间（31天内）')
        return false
      }
      if ((SeachParams.endTime / 1000 - SeachParams.startTime / 1000 + 1) / 60 / 60 / 24 / 31 > 1) {
        this.$message.error('选择的账单时间超过31天，请重新选择')
        return false
      }
      this.isExport = true
      SeachParams.accountType = this.accountType
      _api.billExportExcel(SeachParams).then((res) => {
        const content = res;
        const blob = new Blob([content]);
        let reader = new FileReader(); // 创建读取文件对象
        reader.addEventListener("loadend", () => {
          try {
            let data = JSON.parse(reader.result);
            if (data.code === 0) {
              this.$message.error(data.msg || "服务异常");
            }
          } catch (err) {
            const fileName = '渠道账单.xlsx';
            if ("download" in document.createElement("a")) {
              // 非IE下载
              const elink = document.createElement("a");
              elink.download = fileName;
              elink.style.display = "none";
              elink.href = URL.createObjectURL(blob);
              document.body.appendChild(elink);
              elink.click();
              URL.revokeObjectURL(elink.href); // 释放URL 对象
              document.body.removeChild(elink);
            } else {
              // IE10+下载
              navigator.msSaveBlob(blob, fileName);
            }
          }
        });
        reader.readAsText(blob, "utf-8");

        this.isExport = false
      }).catch(err => {
        console.log("97899999");
        this.isExport = false
      });
    }
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalFormDemo {}
</style>
